const ENVIAR_PEDIDO = 1;
const NUMERO_WHATSAPP_PEDIDO = 2;
const CARRINHO_OBRIGA_ENDERECO_ENTREGA  = 3;
const CARRINHO_OBRIGA_NOME = 4;

export const cfgtDD = {
    ENVIAR_PEDIDO,
    NUMERO_WHATSAPP_PEDIDO,
    CARRINHO_OBRIGA_NOME,
    CARRINHO_OBRIGA_ENDERECO_ENTREGA
}